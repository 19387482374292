/* Общие стили */
:root {
    --background-color: #ffffff;
    --text-color: #333333;
    --border-color: #dddddd;
    --shadow-color: rgba(0, 0, 0, 0.1);
    --button-background: #093aee;
    --button-hover-background: #3575f6;
    --button-text-color: #ffffff;
    --table-header-background: #bf70ef;
    --table-header-text-color: #ffffff;
    --table-row-even-background: #f5f5f5;
    --table-row-hover-background: #eeeeee;
    --modal-background: #ffffff;
    --modal-text-color: #333333;
    --input-background: #ffffff;
    --input-text-color: #333333;
    --input-border-color: #dddddd;
    --placeholder-color: #999999;
    --accordion-background: #ffffff;
    --accordion-text-color: #333333;
    --accordion-border-color: #dddddd;
    --accordion-hover-background: #d9d7d7;
    --light-text-color: #333333; /* Светлый цвет текста по умолчанию */
    --link-hover-background: #dcdcfc;
    --link-background: #eeeefa;
    --color-theme-background: #eeeefa;
    --color-theme-hover-background:  #dcdcfc;
    --color-theme-hover-background:  #dcdcfc;
    --header-background-color: #af4dee, #f886e0;
}

[data-theme="dark"] {
    --background-color: #1e1e1e;
    --text-color: #cccccc; /* Основной текст светло-серый */
    --border-color: #444444;
    --shadow-color: rgba(255, 255, 255, 0.1);
    --button-background: #1c1c1c;
    --button-hover-background: #333333;
    --button-text-color: #ffffff;
    --table-header-background: #444444;
    --table-header-text-color: #ffffff;
    --table-row-even-background: #2d2d2d;
    --table-row-hover-background: #3d3d3d;
    --modal-background: #2d2d2d;
    --modal-text-color: #cccccc; /* Текст модальных окон светло-серый */
    --input-background: #333333;
    --input-text-color: #cccccc; /* Текст инпутов светло-серый */
    --input-border-color: #444444;
    --placeholder-color: #aaaaaa;
    --accordion-background: #2d2d2d;
    --accordion-text-color: #cccccc; /* Текст аккордеона светло-серый */
    --accordion-border-color: #444444;
    --accordion-hover-background: #4e4c4c;
    --light-text-color: #cccccc; /* Светло-серый цвет для текста в темной теме */
    --link-background: #2f2e2e;
    --link-hover-background: #3c3c3c;
    --color-theme-background: #292929;
    --color-theme-hover-background: #3c3c3c;
    --header-box-shadow-background:  0;
    --header-background-color: #f684f1, #250143;

}

#root {
    background-color: var(--background-color) !important;
    color: var(--text-color);
}

html, body {
    height: 100%;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: var(--background-color)!important;
    color: var(--text-color);
    transition: background-color 0.3s ease, color 0.3s ease;
}

.app-page {
    background-color: var(--background-color) !important;
    color: var(--text-color);
}

.article-page {
    background-color: var(--background-color) !important;
    color: var(--text-color);
}

header {
    text-align: center;
    padding: 2px;
    background: linear-gradient(to bottom, var(--header-background-color));
    color: white;
    /*text-shadow: 2px 2px 6px black, -2px -2px 4px gray;*/
    box-shadow: var(--header-box-shadow-background);
    font-family: 'Roboto', sans-serif;
    font-style: normal;
}

.color-theme {
    display: inline-flex;
    cursor: pointer;
    background-color: var(--color-theme-background);
    padding: 7px 11px;
    border-radius: 50%;
    box-shadow: #49494a 3px 4px 4px;
    margin-left: 20px;
}
.color-theme:hover {
    background-color: var(--color-theme-hover-background);
    box-shadow: #49494a 1px 2px 2px;
}

.hidden {
    display: none !important;
}

.page-link {
    text-align: center;
}

.article-brand-link {
    display: inline-flex;
    background-color: var(--link-background);
    font-weight: bold;
    border-radius: 0 0 5px 5px;
}

.active-link {
    background-color: var(--shadow-color); /* Цвет фона для активной ссылки */
}

.brand-link, .article-link {
    transition: background-color 0.3s, color 0.3s; /* Плавный переход */
}

.article-link {
    /*margin-left: 10px;*/
    padding: 7px 20px 10px;
}

.brand-link {
    /*margin-right: 10px;*/
    padding: 7px 20px 10px;
}

.article-link:hover {
    background-color: var(--link-hover-background);
    color: black !important;
    border-radius: 0 0 5px 0;
}

.brand-link:hover {
    background-color: var(--link-hover-background);
    color: black;
    border-radius: 0 0 0 5px;
}

.container {
    max-width: 90%;
    margin: 22px auto 11px;
    padding: 20px;
    background-color: var(--background-color);
    box-shadow: 0 4px 8px var(--shadow-color);
    border-radius: 8px;
}

.auth-form {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    background-color: var(--background-color);
    color: var(--text-color);
}

.query-form {
    position: relative;
}

.query-form-title {
    text-align: center;
    margin-bottom: 25px;
    color: var(--text-color); /* Цвет заголовка формы */
}

.exit-button {
    position: absolute;
    top: 15px;
    right: 10px;
    padding: 0 3px!important;
    color: white !important;
    background: #ee3e09!important;
    height: 30px!important;
    border-radius: 3px!important;
    font-size: 12px !important;
}

.exit-button:hover {
    background: #b82c04 !important;
}

.controls_primary_danger {
    position: absolute;
    top: 15px;
    left: 81%;
}

.auth-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: var(--text-color); /* Цвет заголовка формы */
}

.auth-form .form-control {
    margin-bottom: 15px;
    background-color: var(--input-background);
    color: var(--input-text-color);
    border-color: var(--input-border-color);
}

.auth-form .form-control::placeholder {
    color: var(--placeholder-color);
}

.FaEye-button {
    height: 38px;
    cursor: pointer;
}

.auth-form .btn {
    width: 100%;
    margin-bottom: 10px;
}

.auth-form .btn-link {
    width: auto;
    display: inline-block;
    margin: auto;
    text-align: center;
}

.forgot-registration-button {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;

}

.accordion {
    margin-top: 20px;
    max-height: calc(88vh - 100px);
    overflow-y: auto;
}

.accordion-item {
    background-color: var(--accordion-background);
    border: 1px solid var(--accordion-border-color);
    border-radius: 8px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.accordion-button {
    background-color: var(--accordion-background) !important;
    color: var(--light-text-color); /* Используем светло-серый цвет для текста */
    border: none;
    padding: 15px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.accordion-button:hover {
    background-color: var(--accordion-hover-background) !important;
}

.accordion-button.collapsed {
    background-color: var(--accordion-background)!important;
}
.accordion-button.collapsed:hover {
    background-color: var(--accordion-hover-background) !important;
}


.accordion-body {
    padding: 15px;
    background-color: var(--accordion-background);
    color: var(--light-text-color); /* Используем светло-серый цвет для текста */
    border-top: 1px solid var(--accordion-border-color);
}

.top-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.left-forms {
    width: 55%;
}

.star-mark {
    margin-left: 5px;
}

.star {
    color: red;
    font-weight: bold;
}

.right-controls {
    width: 35%;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 100px; /* Отступ от верха */
    align-self: flex-start; /* Для flex-контейнера */
    gap: 10px;
}

.controls-search-bar .search {
    width: 100%;
}

.request-forms {
    width: 100%;
}

.search-container {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.InputGroupForm {
    display: flex;
    gap: 2px;
}

.message {
    display: inline-block !important;
    background: #43d9e7;
    color: #093aee;
    margin-top: 0;
    padding: 0 10px;
    border-radius: 5px;
}

.message.error {
    display: inline-block !important;
    background: #efb0b0;
    color: red;
    margin-top: 0;
    padding: 0 10px;
    border-radius: 5px;
}

#successMessage {
    display: inline-block !important;
    margin-top: 0;
    padding: 0 10px;
}


.controls_success {
    background: #d98c07 !important;
    border: none !important;
    border-radius: 3px !important;
    color: white !important;
}

.controls_success:hover {
    background: #995103 !important;
}

.controls_primary {
    margin-left: 10px;
    border-radius: 2px !important;
    background: #0899b3 !important;
    color: white !important;
}

.controls_primary:hover {
    background: #036f83 !important;

}

.controls_primary_warning {
    background: #25a908 !important;

}
.controls_primary_warning:hover {
    background: #057202 !important;

}

.delete-button {
    margin-right: 20px;
    padding: 7px;
    margin-left: 5px;
    border-radius: 5px;
    color: var(--light-text-color); /* Используем светло-серый цвет для текста */

}

.delete-button:hover {
    background: #f6f8fa !important;
    color: black;
}

button {
    padding: 5px 10px;
    height: 38px;
    border: none;
    background-color: var(--button-background);
    color: var(--button-text-color);
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
}

button[disabled] {
    background-color: #cccccc;
    cursor: not-allowed;
}

button:hover:enabled {
    background-color: var(--button-hover-background);
    color: var(--button-text-color);
}

table {
    width: 100%;
    border-collapse: collapse;
}

.upload-to-excel {
    /*margin-left: 10px;*/
    background: #08d4cd;
    border: 2px solid #06b9f1;
    border-radius: 4px;
    padding: 3px 7px;
    font-weight: bold;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    gap: 5px;
    min-width: 170px; /* Фиксированная минимальная ширина */
    text-align: center;
}

.upload-to-excel:hover {
    border: 2px solid bisque;
    background: #b9cbcb;
    color: black;
}

.upload-to-excel span {
    font-size: 14px;
}

.upload-to-excel:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
}


.upload-requests {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 7px;
    font-weight: bold;
    font-size: 14px;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #04b808;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 10px;
}


.upload-requests:hover {
    background-color: #e9ecef;
    color: black;
}

.upload-requests:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.upload-to-google-spreadsheet {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 170px; /* Фиксированная минимальная ширина */
    padding: 4px 7px;
    font-weight: bold;
    font-size: 14px;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #07a6ee;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.upload-to-google-spreadsheet:hover {
    background-color: #e9ecef;
    color: black;
}

.upload-to-google-spreadsheet:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}



th, td {
    border: 1px solid var(--border-color) !important;
    padding: 8px;
    text-align: left;
}

th {
    background-color: var(--table-header-background);
    text-align: center;
    color: var(--table-header-text-color);
}

.td_Center {
    text-align: center;
}

tr:nth-child(even) {
    background-color: var(--table-row-even-background);
}

tr:hover {
    background-color: var(--table-row-hover-background);
}

.td_table {
    text-align: center;
    padding: 2px;
}

.td_table_article {
    cursor: pointer;
}

.td_table_article:hover {
    background: var(--background-color);
}

.td_table_page {
    cursor: pointer;
}

.td_table_page:hover {
    background: var(--background-color);
}

.tableIndexRow {
    display: flex;
    width: 100%;
    align-items: center;
}

.accordion_body_table {
    margin-top: 10px;
    margin-bottom: 0;
}

.tableIndexDescription {
    display: flex;
    align-items: center;
}

.tableIndexDescription p {
    margin-left: 10px;
    font-weight: bold;
    color: var(--light-text-color); /* Используем светло-серый цвет для текста */
}

.th_table {
    text-align: center;
    font-size: 13px;
}

.td_table_img {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.td_table_img:hover {
    width: 44px;
}

.td_table_image:hover {
    background: var(--background-color);
}

.flex-grow-0 {
    margin-right: 10px;
    font-weight: bold;
    color: var(--light-text-color); /* Используем светло-серый цвет для текста */
}

.flex-grow-1 {
    flex-grow: 1;
    color: var(--light-text-color); /* Используем светло-серый цвет для текста */
}

.date-time {
    margin-left: auto;
    margin-right: 20px;
    text-align: right;
    color: var(--light-text-color); /* Используем светло-серый цвет для текста */
}

.search input {
    padding: 5px 10px;
    font-size: 14px;
    height: 38px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    flex-grow: 1;
    background-color: var(--input-background);
    color: var(--input-text-color);
}

.search input::placeholder {
    color: var(--placeholder-color);
}

.images_table {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    position: relative;
    background-color: var(--modal-background);
    padding: 20px;
    border-radius: 10px;
    color: var(--modal-text-color);
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.modal-image {
    max-width: 90vw;
    max-height: 90vh;
}

.message, .message.error, #successMessage, .no-results {
    display: inline-block !important;
    background: #43d9e7;
    color: #093aee;
    margin-top: 0;
    margin-bottom: 5px;
    padding: 2px 8px;
    border-radius: 5px;
    font-size: 14px;
    animation: fadeIn 0.5s ease-in-out;
}

.message.error {
    background: #efb0b0;
    color: red;
}

.no-results.alert-success {
    background: #ffcccb !important;
    color: #000000 !important;
}

.accordion-button.collapsed {
    height: auto !important;
    padding: 7px 15px !important;
}

.accordion-button {
    height: auto !important;
    padding: 7px 15px !important;
}

.logout-container Button {
    font-size: 14px;
    padding: 10px 20px;
    background-color: #b80601;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 90%;
}

.logout-container Button:hover {
    background-color: #c9302c;
}


/* Адаптивные стили */
@media (max-width: 768px) {

    html, body, #root {
        width: auto;
        overflow-x: auto;
        overflow-y: auto;
    }

    .header-logoWb {
        width: 120px;
    }

    .container {
        margin-top: 32px;
        padding: 5px;
        box-shadow: 4px 4px 4px 4px var(--shadow-color);
        overflow-x: auto;
        overflow-y: auto;
    }

    .auth-container {
        margin: 4px 0;
    }

    .article-brand-link {
        display: inline-flex;
        background-color: var(--link-background);
        font-weight: bold;
        border-radius: 0 0 5px 5px;
        width: 100%;
    }

    .article-link {
        /*margin-left: 12px;*/
        padding: 7px 15px 10px 12px;
        font-size: 15px;
        width: 50%;
    }

    .brand-link {
        /*margin-right: 12px;*/
        padding: 7px 15px 10px 12px;
        font-size: 15px;
        width: 50%;
    }

    #password.form-control {
        width: 20% !important;
    }


    .color-theme {
        margin-top: 10px;
        margin-left: 0;
        margin-bottom: -10px;
    }

    .rbt-input-main.form-control.rbt-input {
        font-size: 14px;
    }

    .dropdown-toggle.btn.btn-primary {
        border-radius: 4px!important;
    }

    .btn.btn-primary {
        border-radius: 4px!important;
    }

    .btn.btn-secondary {
        border-radius: 4px!important;
    }

    .accordion {
        height: 270px;
    }

    .top-section {
        flex-direction: column;
        gap: 10px;
    }

    .form-control {
        width: 100% !important;
    }

    .left-forms, .right-controls {
        width: 100%;
    }

    .InputGroupForm {
        flex-direction: column;
        gap: 5px;
    }

    .search-container {
        flex-direction: column;
    }

    .controls {
        flex-direction: column;
        gap: 5px;
    }

    .controls_primary {
        margin-left: 0;
    }

    .controls_primary_warning {
        margin-left: 10px;
        font-size: 13px!important;
    }

    .accordion {
        max-height: calc(80vh - 100px);
    }

    .accordion-header-small {
        width: 100%;
    }

    .delete-button-small {
        margin-top: 22px !important;
        margin-left: 95%;
        margin-bottom: 0;
    }

    .delete-button-small svg {
        margin-top: 11px;
    }

    .date-time-small {
        margin-top: 3px;
        margin-bottom: 7px;
    }

    .accordion-body {
        padding: 30px 10px;
        overflow-x: auto;
        overflow-y: auto;
        white-space: nowrap;
        max-width: 100%;
    }

    .accordion-button {
        padding: 10px !important;
    }

    .accordion-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .date-time {
        margin-left: 0;
        margin-right: 0;
        text-align: left;
    }

    .modal-content {
        width: 90%;
        padding: 10px;
    }

    .modal-image {
        max-width: 100%;
    }

    .auth-form {
        padding: 15px;
    }

    .auth-form h2 {
        font-size: 1.5rem;
    }

    .auth-form .btn {
        font-size: 14px;
    }

    .forgot-registration-button {
        display: inline-flex;
        width: 100%;
        justify-content: space-between;

    }

    .registration-button {
        /*margin-left: 13px !important;*/
        /*margin-top: 10px;*/
    }

    .exit-button {
        position: static;
        margin-bottom: 10px;
    }

    .controls_primary_danger {
        position: static;
        /*margin-bottom: 10px;*/
        margin-right: 10px;
    }

    .query-form-title {
        font-size: 1.5rem;
    }

    .td_table_img {
        width: 30px;
        height: 30px;
    }

    .th_table, .td_table {
        font-size: 14px;
    }

    .tableIndexDescription p {
        font-size: 14px;
    }

    .exit-button.btn.btn-danger {
        margin-top: 10px;
        padding: 0 3px;
        font-size: 12px;
    }

    .controls_success.btn.btn-primary {
        font-size: 12px;
        padding: 0 5px;
    }

    .controls_primary.btn.btn-primary {
        margin-left: 15px;
        font-size: 12px;
        padding: 0 5px;
    }

    .buttons-sheets {
        display: inline-flex;
    }

    .upload-to-google-spreadsheet {
        min-width: 60px;
        font-size: 13px;
        padding: 4px 5px;

    }

    .upload-to-excel {
        min-width: 55px;
        font-size: 13px;
        padding: 4px 5px;
    }

}

@media (max-width: 480px) {
    header h1 {
        font-size: 1.5rem;
    }

    .auth-form h2 {
        font-size: 1.2rem;
    }

    .query-form-title {
        font-size: 1.2rem;
    }

    .accordion-button {
        font-size: 14px;
    }

    .accordion-body {
        padding: 5px;
    }

    .td_table_img {
        width: 25px;
        height: 25px;
    }

    .th_table {
        font-size: 12px;
    }

    .tableIndexDescription p {
        font-size: 14px;
    }

    .modal-content {
        width: 95%;
        padding: 5px;
    }
}





